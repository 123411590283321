<template>
  <div class="left widget" id="_bn_widget_">
    <a href="http://bnovo.ru/" id="_bnovo_link_" target="_blank">Bnovo</a>
  </div>
</template>

<script>

export default {
  name: "BookingWidget",
  mounted() {
    let scriptEl = document.createElement('script');
    scriptEl.setAttribute('src', '//widget.reservationsteps.ru/js/bnovo.js');
    scriptEl.onload = () => {
      // eslint-disable-next-line no-undef
      Bnovo_Widget.init(function () {
        // eslint-disable-next-line no-undef
        Bnovo_Widget.open('_bn_widget_', {
          type: "horizontal",
          uid: "901f17e2-e78a-4708-9f6a-1f3aa654c3f6",
          lang: "ru",
          currency: "RUB",
          width: "80%",
          width_mobile: "300",
          background: "#ffffff",
          background_mobile: "#ffffff",
          bg_alpha: "65",
          bg_alpha_mobile: "100",
          border_color_mobile: "#C6CAD3",
          padding: "24",
          padding_mobile: "24",
          border_radius: "8",
          button_font_size: "14",
          button_height: "42",
          font_type: "inter",
          title_color: "#000000",
          title_color_mobile: "#242742",
          title_size: "22",
          title_size_mobile: "22",
          inp_color: "#000000",
          inp_bordhover: "#000000",
          inp_bordcolor: "#000000",
          inp_alpha: "10",
          btn_background: "#FFC629",
          btn_background_over: "#FFC629",
          btn_textcolor: "#000000",
          btn_textover: "#FFFFFF",
          btn_bordcolor: "#000000",
          btn_bordhover: "#000000",
          adults_default: "1",
          dates_preset: "on",
          dfrom_today: "on",
          dfrom_value: "2",
          dto_nextday: "on",
          dto_value: "2",
          cancel_color: "#FFC629",
          title: 'Забронировать',
          font_size: "16",
          url: `${window.location.origin}/reservations`,
          switch_mobiles_width: "800",
          form_group: "styles",
        });
      });
    };
    document.head.appendChild(scriptEl);
  },
}
</script>

<style>
</style>
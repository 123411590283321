<template>
  <Page title="Афиша">
    <template v-slot:content>
      <div class="events">
        <div v-for="i in events" :key="i.id" class="event-wrap">
          <div class="event">
            <div v-if="i.image" class="event-image">
              <img :src="i.image"/>
            </div>
            <div v-else-if="i.video" class="event-image">
              <video :src="i.video" muted autoplay loop></video>
            </div>
            <div class="event-content" v-if="i.title || i.text">
              <div class="event-title">
                {{ i.title }}
              </div>
              <div class="event-text">
                <p v-html="i.text"/>
              </div>
              <div class="button-wrap">
                <a v-if="i.link" class="button" target="_blank" :href="i.link">
                  Купить билеты
                </a>
                <a v-if="i.file" class="button" target="_blank" :href="i.file" download>
                  Расписание
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </Page>
</template>

<script>
import Page from "../layout/Page";
import img13 from '@/assets/eventss/afisha124.jpg';
import img14 from '@/assets/eventss/afisha123.jpg';
import img15 from '@/assets/eventss/afisha125.jpg';
import img16 from '@/assets/eventss/afisha144.jpg';

export default {
  name: "Events",
  components: {Page},
  data: () => {
    return {
      events: [
          /* Йога-тур «Дыхание Архыза»
              7 дней/6 ночей
              Вы готовы погрузиться в гармонию тела, ума и духа?
              Мы (https://svarkhyz.ru/) приглашаем вас присоединиться к нашему исключительному туру по хатха-йоге в живописном Архызе!
              Что вас ждёт и почему тур по хатха-йоге в Архызе станет для вас идеальным выбором?
              Переходим в чат, где есть вся информация по туру. (https://t.me/c/1770641727/2686)
              Присоединяйтесь к нам в Архызе и позвольте себе раствориться в потоке йоги, природы и спокойствия.
              Даты тура (https://t.me/c/1770641727/2698)
              svarkhyz.ru
              */
        {
          id: 16,
          title: 'Йога-тур «Дыхание Архыза»',
          text: '<b>Вы готовы погрузиться в гармонию тела, ума и духа?</b><br/> Мы приглашаем вас присоединиться к нашему исключительному туру по хатха-йоге в живописном Архызе!<br/>' +
              '<b>Что вас ждет:</b><br/>' +
              'Ежедневные практики йоги с опытным инструктором, рассчитанные на все уровни подготовки. Завораживающие пейзажи в качестве вдохновляющего фона.<br/>' +
              'Проживание в уютных номерах парк-отеля SUN VILLAGE Arkhyz, расположенного в сосновом бору на высоте 1550 м над уровнем моря в окружении гор Западного Кавказа, где вы сможете расслабиться и восстановить силы.<br/>' +
              'Питание 3-х разовое, учитывающее принципы йогической диеты, чтобы зарядить вас энергией.<br/>' +
              '<b>А также в программе:</b><br/> термальные источники, увлекательные экскурсии в Специальную астрофизическую Обсерваторию РАН и ферму Альпак, поездка на джипах к Белым водопадам, чаепитие на свежем воздухе, яркие вечерние мероприятия в приятной компании.<br/>' +
              'Почему тур по хатха-йоге в Архызе станет для вас идеальным выбором?<br/>' +
              'Глубокая практика йоги: Улучшите свою гибкость, силу и баланс в окружении захватывающей природы.<br/>' +
              'Духовное пробуждение: Откройте для себя внутреннюю гармонию и осознанность, живя в настоящем моменте.<br/>' +
              'Потрясающая природа: Вдохновитесь величественными горами, кристально чистыми озерами, горным<br/>' +
              'и пеками и пышными хвойными лесами.<br/>' +
              '<b>Полная перезагрузка:</b><br/> Отвлекитесь от повседневной суеты, восстановите силы и вернитесь домой обновленными.<br/>' +
              'Общение с единомышленниками: Познакомьтесь с другими любителями йоги и поделитесь своим опытом.<br/>' +
              '<b>Присоединяйтесь к нам в Архызе и позвольте себе раствориться в потоке йоги, природы и спокойствия.</b><br/>' +
              'ВАЖНО! Трансфер включен в стоимость: аэропорт или ж/д вокзал г. Минеральные Воды – Отель - аэропорт или ж/д вокзал г. Минеральные Воды<br/>' +
              'Билеты до аэропорта или ж/д вокзала г. Минеральные Воды оплачиваются самостоятельно.',
          image: img16,
          link: '',
          file: '/docs/yoga-tour.xlsx',
        },
        {
          id: 15,
          title: '2 марта',
          text: 'Впервые в Архызе, в ресторанно-развлекательном комплексе парк-отеля SUN VILLAGE Arkhyz пройдёт концерт группы "ШАТ Band"',
          image: img15,
          link: 'https://one.timepad.ru/event/2798920/',
        },
        {
          id: 14,
          title: '23 февраля в ресторане парк - отеля SUN VILLAGE Arkhyz',
          text: 'Весь вечер будет музыка и танцы, розыгрыш призов и много всего интересного! <br/>' +
              'Весь вечер с нами: Аккордеонист - виртуоз - Юрий Дворников - Дважды выступал в Кремлёвском Зале , как лучший Аккордеонист Юга России<br/>' +
              'Прекрасная певица, Мисс Ставрополь - Оксана Белозёрова <br/>' +
              'Лауреат Всероссийских и Международных конкурсов артистов эстрады<br/>' +
              'Постоянный ведущий Международного фестиваля "Союз Талантов России" г. Москва<br/>' +
              'Член союза писателей России<br/>' +
              'Автор - исполнитель - шоумен <br/>' +
              'Сергей Лещёв<br/>' +
              '‼️Специальный сет от шефа «настоящий полковник»‼️<br/>' +
              'Время: 19-22:00<br/>' +
              '22:00-01:00 Дискотека',
          image: img14,
        },
          {
            id: 13,
            text: 'Парк-отель SUN VILLAGE Arkhyz <br/>' +
                'приглашает на концерт-диско<br/>' +
                'лучшей кавер-группы Юга России - «ЛИЧНОСТИ» 💣<br/>' +
                '24 февраля 2024г. <br/>' +
                'Начало концерта в 21:00<br/>' +
                'Бронирование столов и билетов по тел.: 7 (928) 357-57-66<br/>' +
                '+7 (920) 995-32-07<br/>' +
                '<br/>' +
                'Стоимость билета:1500 руб + 🔥 напиток<br/>' +
                'Для милых дам: 500 руб. +🍹',
            image: img13,
          },
          {
            id: 12,
            video: '/videos/events/afisha-1.mp4',
            title: 'Каждый ЧЕТВЕРГ вечер КИНО в SunVillage Arkhyz!',
            text: '<b>Старт в 20:00</b><br/><br/>' +
                'Звоните и бронируйте фильм и время по телефону: <a href="tel:79209953207">+7 (920) 995-32-07</a><br/>' +
                '<br/>' +
                'Наш уютный кино-зал с комфортабельными креслами ждет Вас и Ваших друзей!'
          },
          {
            id: 11,
            video: '/videos/events/afisha-2.mp4',
            title: 'Каждую ПЯТНИЦУ и СУББОТУ ждём Вас в Sun Village Arkhyz!',
            text: '<b>Старт в 20:00</b><br/><br/>' +
                'Танцы, коктейли и хорошее настроение.<br/>' +
                'DJ Paul<br/>' +
                'Вход-свободный<br/>' +
                'Бронь столов по телефону: <a href="tel:79209953207">+7 (920) 995-32-07</a><br/>'

          },
          {
            id: 10,
            image: 'images/events/event10.jpg',
            text: 'Кто ещё не знает её знакомьтесь, идейный вдохновитель <a href="https://t.me/sunvillagearkhyz/666">фестиваля<a/> <a href="https://t.me/sunvillagearkhyz/648">Другой Звук </a>-<br/>' +
                'Анна Цыбулёва (на фото) уроженка Архыза, концертирующая пианистка, победительница одного из самых престижных международных конкурсов пианистов «The Leeds International Piano competition» (Великобритания), умница и красавица💕\n' +
                '<br/><br/>' +
                '⭐️ Анна снова удивляет - создав музыкальное путешествие, в котором нет шаблонов, границ и стереотипов. <br/>' +
                '🎼 Придя на концерты, Вы не только услышите шедевры мировой классики, но и узнаете, как в музыке кодируют эмоции и что такое треугольник эмпатии.\n' +
                '<br/>' +
                '<a href="https://futuretimefoundation.timepad.ru/event/2342269/">Завтрак с учёным</a> «Наука как повод проснуться» 2.03 в парк-отеле «SUN VILLAGE Arkhyz»\n' +
                '<br/>' +
                '<a href="https://futuretimefoundation.timepad.ru/event/2342274/">Бар с музыкантом</a> «Игра в четыре руки. Как жить, если вы оба – музыканты» 2.03 в парк-отеле «SUN VILLAGE Arkhyz»\n' +
                'Вход свободный' +
                '<br/>' +
                '<a href="https://widget.afisha.yandex.ru/w/sessions/NTcyODV8MzcyNTQzfDQ3NzMyNTN8MTY3Nzc3MjgwMDAwMA==?clientKey=9c32af70-d771-439b-add6-fad0c0a29703">Купить билет</a> на концерт «Азбука музыки» в парк-отеле «SUN VILLAGE Arkhyz» (2.03 в 19:00)'
          },
          {
            id: 9,
            image: 'images/events/event9.jpg',
            text: '<b>Фестиваль музыки и науки «Другой звук» снова с вами!</b>' +
                '<p>c 1 по 4 марта приглашаем вас на концерты и встречи с удивительными людьми: музыкантами и учеными.<br/>' +
                'Концерты «Другого звука» - отличная возможность поменять спортивный костюм на вечерний наряд и переместиться с горных вершин в пространство романтики и классической музыки. Молодые музыканты из ведущих оркестров России привезли в Архыз шедевры Баха, Бетховена, Бартока и других известных композиторов. \n' +
                '<br/><br/>' +
                'Концерты пройдут 1, 2 и 4 марта в МФК «Романтик», парке-отеле «SUN VILLAGE Arkhyz» и Специальной астрофизической обсерватории РАН.\n' +
                '<br/><br/>' +
                'Для тех, кто любит живой разговор – наши завтраки и бары с учёными и музыкантами. Поболтаем с профессионалами, узнаем много нового, зададим каверзные вопросы, посмеемся. События состоятся 1 и 2 марта в ресторанах apart-отеля «Axis» и парка-отеля «SUN VILLAGE Arkhyz».\n' +
                '<br/>' +
                'Для знакомства с программой и регистрации на события переходите <a href="https://altsono.ru/">по ссылке</a>.' +
                '<br/>' +
                'Большой Фестиваль «Другой звук» – в августе 2023, следите за новостями!</p>',
          },
          {
            id: 8,
            image: 'images/events/event8.jpg',
            text: 'Сегодня последний день зимы и мы хотим всех гостей курорта Архыз порадовать солнечной игрой аккордеониста - виртуоза Юрия Дворникова 🪗<br/>' +
                '<br/>' +
                'Как символично, что  он дважды выступал в Кремлёвском Зале , как лучший Аккордеонист Юга России и уже дважды будет выступать в парк-отеле SUN VILLAGE Arkhyz 😻'
          },
          {
            id: 7,
            image: '/images/events/event7.jpg',
            text: '23 февраля в ресторане парк-отеля SUN VILLAGE Arkhyz пройдёт праздничная  программа «Да здравствуют мужчины!»<br/>' +
                '<br/>Весь вечер с вами:' +
                '<br/>🪗 лучший Аккордеонист Юга России - Юрий Дворников <br/>' +
                '🎤 Прекрасная певица, Мисс Ставрополь - Оксана Белозёрова <br/>' +
                '⭐ Автор, исполнитель, шоумен - Сергей Лещев.<br/>' +
                'Лауреат Всероссийских и Международных конкурсов артистов эстрады. Постоянный ведущий Международного фестиваля "Союз Талантов России" (г.Москва)\n' +
                '<br/>Зажигательная музыка и танцы, розыгрыш призов и много всего интересного!<br/>' +
                '<br/>Начало в 19:00'
          },
          {
            id: 6,
            video: '/videos/events/event6.MP4',
            title: 'Скрипач  и композитор - Павел Дедер, уроженец Пятигорска.',
            text: '<p>Скрипкой увлекается с 4 лет, а в 6 играл Вивальди с симфоническим оркестром. Учился в Музыкальном училище имени В. И. Сафонова в Минеральных Водах, в Ростовской государственной консерватории им. Рахманинова, а также в польском институте искусств. Несколько лет артист сотрудничал с продюсерским центром Макса Фадеева MALFA и  был участником проекта Наргиз Закировой, гастролировал по России и всему миру.</p>' +
                '<b>Хотите услышать как звучит немецкая мастеровая  скрипка Joseph Berger 1903 года?</b>' +
                '<p>Начало в 19:00' +
                'Бронирование столов по телефону +7 (928) 386-94-05</p>',
          },
          {
            id: 5,
            image: '/images/events/event5.jpg',
            title: 'В парк-отеле SUN VILLAGE Arkhyz прошёл обучающий семинар  «СТАНДАРТ ОСНАЩЕНИЯ ГОСТИНИЧНОГО НОМЕРА. ОПЫТ И РЕКОМЕНДАЦИИ» компании «Еврономер» при поддержке Торгово-Промышленной палаты КЧР',
            text: '<p>На мероприятии присутствовали собственники и менеджеры курортных отелей и специалисты отрасли. Спикерами были детально разобраны темы оснащения номерного фонда, качественной звукоизоляции отеля, меры господдержки инвестиционных проектов индустрии гостеприимства, и еще несколько важных тем.</p>' +
                '<p>Одним из спикеров семинара выступил представитель госкорпорации <a href="https://туризм.рф" target="_blank" >ТУРИЗМ.РФ</a> - Михаил Вагайцев.</p> ',
          },
          {
            id: 4,
            image: '/images/events/event4.jpg',
            title: '22.11.22 - 29.11.22, 7 дней, 6 ночей. АРХЫЗ, SUN VILLAGE Arkhyz Карачаево - Черкессия. Зимняя сказка в горах!\n',
            text: '<p>Третий тур в России с насыщенной программой! Второй тур был просто прекрасен. Да, мы нашли свою площадку - с чудесным огромным новым дизайнерским чилл-аутом и площадкой для занятий в плохую погоду. Да, мы убедились окончательно, что Метод невероятно заходит и продвинутым, и новичкам. </br>' +
                'Но мы делаем программу еще интереснее!</p>' +
                '<p>Три кита: метод Вима Хофа, горная медитативная баня, приключения + день горнолыжки/трекинга на выбор. Твоя встреча с самим собой, настоящим! Твоя личная встреча с тем, что мешает тебе быть по-настоящему счастливым. Твое качество жизни, энергия, здоровье и долголетие.</p>' +
                '<p>С участием единственного в РФ официального инструктора Метода Вима Хофа Алексея Мальцева.</br>' +
                'От соорганизатора фестиваля "ДЫШИ" (2015- н.в.) Евгения Титкова.<p>' +
                '<p>САМОЕ ПОЛНОЕ И УДОБНОЕ ОПИСАНИЕ В ВИДЕ ЧАТ-БОТА <a target="_blank" href="https://vk.com/app5898182_-9294827#s=1917152"> здесь</a></p>',
          },
          {
            id: 3,
            image:  '/images/events/event3.jpg',
            title: 'С 4 по 6 ноября на курорте Архыз прошел фестиваль «Осенние каникулы» от музыкального проекта Funk & Beats. Партнером фестиваля выступил парк-отель SUN VILLAGE Arkhyz.',
            text: '<p>Двое суток на площадке концертно-развлекательного комплекса парк-отеля, в режиме non-stop, играли лучшие DJ современности, такие как: </p>' +
                '<ul><li>Symbol [live] (Москва)</li>' +
                '<li>Sofia Rodina (Москва)</li>' +
                '<li>Zuula [live] (Ростов-на-Дону)</li>' +
                '<li>b0n ( Пятигорск)</li>' +
                '<li>Fillimonov (Сочи)</li>' +
                '<li>Lana Rossa (Сочи)</li>' +
                '<li>HQ (Москва)</li>' +
                '<li>Desonlace (Ростов-на-Дону)</li>' +
                '<li>Omul (Ростов-на-Дону)</li>' +
                '<li>Bidjo (Москва)</li>' +
                '<li>Pandamoon (Ростов-на-Дону)</li>' +
                '<li>Barmet (Ростов-на-Дону)</li>' +
                '<li>Ega Veega (Краснодар)</li></ul>' +
                '<p>and more..<br/><br/>' +
                'В Фестивале приняло участие более 400 гостей курорта, большая часть из которых приехали специально для участия в фестивале современной электронной музыки. Помимо музыкальных сетов у участников фестиваля была возможность посетить местные достопримечательности и попробовать аутентичную кавказскую кухню. Организаторы фестиваля  отметили отличный звук и акустику комплекса и анонсировали проведение «Осенних каникул» в будущем году. \n' +
                'Закрытая вечеринка вызвала огромный резонанс на курорте и стала основным событием этого сезона, погрузив в музыкальную лихорадку всю поляну Таулу.</p>'
          },
          {
            id: 2,
            image: '/images/events/event2.jpg',
            title: 'Концерт классической музыки в исполнении струнного оркестра «Alter Sono» и солистов пройдёт на высоте две тысячи метров в горах Архыза.',
            text: 'Концерт и разговор с исполнителями и учеными будет посвящён взаимосвязи ритмов мирозданья и творчества. Музыка и неформальная беседа помогут найти простые и действенные способы освободиться от навязанных границ, вдохновиться идеями композиторов прошлых столетий и сравнить их с современностью.\r\n' +
                '<br/><br/><b>Произведения</b>: Э. Элгар «Серенада для струнных», К. Сен-Санс «Рондо Каприччиозо», М. Брух «Романс» для альта, Артемьев «Свой среди чужих», П. Масканьи Интермеццо из оперы Сельская честь, А. Вивальди «Времена года: Зима».',
          },
          {
            id: 1,
            image: '/images/events/event1.png',
            title: 'Вечер седьмого августа в парк-отеле “SUN VILLAGE Arkhyz” украсит сольный концерт певицы НАТАЛЬИ ЛЕРНЕР',
            text: 'Наталья с детства мечтала посвятить жизнь пению и эта мечта воплотилась в жизнь в полной мере - она лауреат  международных вокальных конкурсов, длительное время являлась солисткой группы «Весна», а в настоящее время успешно выступает сольно. \n' +
                'Пережив тяжёлые заболевания и операции на сердце, Наталья называет настоящий период в своём творчестве -«новым рождением». \n' +
                'Насладиться душевным исполнением Натальи Лернер вы сможете уже скоро. <br/><br/>' +
                '7 августа, в 21:30 \n' +
                'Место проведения: \n' +
                'Парк-отель «SUN VILLAGE Arkhyz" \n' +
                'Курорт Архыз, поляна Таулу'
          }
      ]
    }
  }
}
</script>

<style>
.event-content a{
  color: #076fcb;
}
</style>
<style scoped>
.events{
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.event-wrap{
  background-color: #fff;
}
.event{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 15px;
  flex-wrap: wrap;
  border: 1px solid #484848;
  padding: 1rem;
}
.event-image{
  width: 350px;
  max-width: 100%;
  flex-shrink: 0;
  position: relative;
  min-height: 400px;
  max-height: 100%;
  overflow: hidden;
  text-align: center;
}
.event-image video{
  max-height: 400px;
}
.event-image img{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  max-height: 100%;
}
.event-content{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
  max-width: 600px;
}
.event-title{
  font-size: 14pt;
  font-weight: bold;
}
.event-text{
  text-align: left;
  font-size: 12pt;
}
.button-wrap, .button{
   width: 100%;
}
.button{
  display: block;
  cursor: pointer;
  background-color: #ffc629;
  color: #000000;
  text-align: center;
  font-weight: bold;
  padding: .5rem;
  margin-top: 8px;
}
button:hover{
  text-decoration: none;
}
</style>
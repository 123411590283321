<template>
<div class="page">
  <div :class="'background ' + 'background--'+page.component">
<!--    <img v-if="page.background" :src="page.background"/>-->
  </div>
  <div :class="'title-group ' + 'title-group--'+page.component">
    <div class="title">{{ page.title }}</div>
    <div class="title-after"></div>
  </div>

  <div class="block">
    <component :is="page.component" :loading="loading"></component>
  </div>
</div>
</template>

<script>
import Accommodation from "@/components/layout/pages/Accommodation";
import Contact from "@/components/layout/pages/Contact";
import About from "@/components/layout/pages/About";
import Sauna from "@/components/layout/pages/Sauna";

export default {
name: "Block",
  components: {Accommodation, Contact, About, Sauna },
  props: {
    page: Object,
  },
  data(){
    return{
      loading: true,
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.loading = false;
    })
  },
}
</script>

<style scoped>
.page{
  /*border-top: 2px solid #000;*/
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  position: relative;
  overflow: hidden;
}
.title-group{
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}
.title{
  margin-top: 0;
  margin-bottom: 1rem;
  font-weight: bold;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 26pt;
  height: 45px;
  line-height: 1.1;
  text-align: left;
  padding-left: 5rem;
  text-transform: uppercase;
  border-bottom: 3px solid #000;
  width: 50%;
  flex: 1;
  z-index: 100;
}
.title-after{
  height: 5rem;
  flex: 1;
  border-top: 3px solid #000;
  margin-left: 5rem;
}

.background{
  position: absolute;
  background-position: center;
  background-size: cover;
  background-color: #d0d0d0;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
}
.background img{
  width: 100%;
  background-position: center center;
}
.block{
  padding: 0 8rem;
}
@media (max-width: 1280px) {
  .block{
    padding: 0 6rem;
  }
}
@media (max-width: 960px) {
  .block{
    padding: 0 4rem;
  }
}
@media (max-width: 480px) {
  .block{
    padding: 0 0;
  }
  .title{
    padding-left: 1rem;
    white-space: nowrap;
  }
  .title-after{
    margin-left: 3rem;
  }
}
</style>

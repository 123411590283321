<template>
  <a @click="goHome()">
    <img src="@/assets/logo_128.png" alt="Sun Village Arkhyz"/>
  </a>
</template>

<script>
export default {
  name: "Logo",
  methods: {
    goHome() {
      if (this.$route.name === 'home') {
        this.$root.$emit('goToPage', {route: 'main'});
      } else {
        this.$router.push('/home/main');
      }
    },
  },
}
</script>

<style scoped>
img{
  width: 100%;
  border-radius: 50%;
}
</style>

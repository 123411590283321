var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Page',{attrs:{"title":"Экскурсии"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"excursions"},_vm._l((_vm.excursions),function(item){return _c('div',{key:item.id,staticClass:"excursions-block",class:{full: item.full}},[(item.full)?_c('img',{staticClass:"excursions-block-media-full",attrs:{"alt":item.title,"src":item.image}}):(item.image)?_c('img',{staticClass:"excursions-block-media",attrs:{"alt":item.title,"src":item.image}}):(item.video)?_c('video',{staticClass:"excursions-block-media",attrs:{"src":item.video,"controls":""}}):_vm._e(),(item.title)?_c('h3',[_vm._v(_vm._s(item.title))]):_vm._e(),(item.text)?_c('div',{staticClass:"excursions-block-text",domProps:{"innerHTML":_vm._s(item.text)}}):_vm._e(),(item.popup)?_c('div',{staticClass:"excursions-block-button",on:{"click":function($event){return _vm.$root.$emit('popup', {
             type: 'text',
             content: {
               title: item.title,
                text: item.fulltext
             }
           })}}},[_c('span',[_vm._v("Читать далее")])]):_vm._e()])}),0)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }
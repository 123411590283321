<template>
  <Page title='Ресторан'>
    <template v-slot:content>
      <div class="cafe">
        <p class="text">
          В ресторанно-развлекательном комплексе SUN VILLAGE Arkhyz открыт ресторан современной кавказской кухни.<br/>
          Время работы ресторана с 11:00 до 00:00, завтраки проходят с 9:00 до 11:00.<br/>
          Для гостей не проживающих в отеле, завтрак 850р.<br/>
          Так же работает служба доставки. Стоимость доставки от 300р.<br/>
          Бронь столов и доставка по телефону: <a href="tel:+79283575766">+7 (928) 375-72-74</a>
        </p>
        <a class="sublink" href="/misc/menu.jpg" download="Menu.jpg">Основное меню</a>
        <br/>
        <br/>
        <a class="sublink" href="/misc/menu.jpg" download="Bar.jpg">Пивное меню</a>
<!--          <div class="image" v-for="i in images" :key="i.src" @click="openImage(i.src)">-->
<!--            <img :src="i.src" :alt="i.alt"/>-->
<!--          </div>-->
          <div class="slider-wrp">
            <ImageSlider3 class="slider" :images="photos"/>
          </div>
      </div>
      <div></div>
    </template>
  </Page>
</template>

<script>
import Page from "../layout/Page";

import rest1 from '@/assets/restaurant/rest1.jpg';
import rest2 from '@/assets/restaurant/rest2.jpg';
import rest3 from '@/assets/restaurant/rest3.jpg';
import rest4 from '@/assets/restaurant/rest4.jpg';
import ImageSlider3 from "../layout/content/ImageSlider3.vue";

export default {
  name: "Restaurant",
  components: {ImageSlider3, Page },
  data: () => ({
    images: [
      { id: 0, src: rest1, alt: 'Фото кафе'},
      { id: 1, src: rest2, alt: 'Фото кафе'},
      { id: 2, src: rest3, alt: 'Фото кафе'},
      { id: 3, src: rest4, alt: 'Фото кафе'},
        ]
  }),
  computed: {
    photos(){
      return this.images.map((el) => el.src);
    }
  },
  methods: {
    openImage(i){
      this.$root.$emit('popup', {type: 'image', content: i});
    }
  },
}
</script>

<style scoped>
.text{
  font-size: 12pt;
}
.images{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 1rem;
  padding-top: 1rem;
}
.image{
  max-width: 350px;
  cursor: pointer;
}
.slider-wrp{
  height: 12rem;
  padding: 2rem 0;
}
.slider{
  height: 12rem;
}
.sublink{
  font-size: 12pt;
  font-weight: bold;
}
</style>

<template>
  <section class="swiper-section">
    <swiper class="swiper" :options="swiperOptions">
      <swiper-slide v-for="i in images" :key="i">
        <div v-if="typeof i === 'object'" class="slide-wrap">
          <div class="video-wrap">
            <video class="video" :src="`https://svarkhyz.ru${i.src}`" controls></video>
          </div>
<!--          <div v-if="i.text" class="video-text"> {{ i.text }}</div>-->
        </div>
        <div v-else-if="video" class="video-wrap slide-wrap">
          <video class="video" :src="`https://svarkhyz.ru${i}`" controls></video>
        </div>
        <ImageBox v-else class="image" :image="i" @click="openImage(i)"></ImageBox>
      </swiper-slide>
    </swiper>
  <div :class="`swiper-button-prev swiper-button-prev-${this.randKey}`" slot="button-prev"></div>
  <div :class="`swiper-button-next swiper-button-next-${this.randKey}`" slot="button-next"></div>
  </section>
</template>

<script>
import {Swiper, SwiperSlide} from 'vue-awesome-swiper';
import 'swiper/css/swiper.min.css';
import ImageBox from "@/components/layout/content/ImageBox";
export default {
  name: "ImageSlider3",
  components: {ImageBox, Swiper, SwiperSlide},
  data(){
   return {
     randKey: Math.random().toString().substr(2, 5),
   };
  },
  props: {
    images: Array,
    video: {
      type: Boolean,
      default: false,
    },
    perView: {
      type: Number,
      default: 3,
    }
  },
  computed: {
    swiperOptions(){
      return {
        slidesPerView: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? 1 : this.perView,
        spaceBetween: 10,
        navigation: {
          nextEl: '.swiper-button-next-'+this.randKey,
          prevEl: '.swiper-button-prev-'+this.randKey,
        }
      }
    },
  },
  methods: {
    openImage(i){
      this.$root.$emit('popup', {type: 'image', content: i});
    }
  }
}
</script>

<style scoped>
.swiper-section{
  height: 100%;
  position: relative;
}
.swiper{
  /*background-color: rgba(0,0,0,0.7);*/
  height: 100%;
}
.image{
  cursor: zoom-in;
}
.swiper-button-next, .swiper-button-prev{
  color: #ffc629;
  top: 50%;
}
.swiper-button-next{
  /*right: -2rem;*/
  /*text-shadow: -3px 0 #484848;*/
}
.swiper-button-prev{
  /*left: -2rem;*/
  /*text-shadow: 3px 0 #484848;*/
}
.slide-wrap{
  height: 100%;
  display: flex;
  flex-direction: row;
  border: 1px solid #bfbfbf;
  padding: 10px;
}
.video-wrap{
  background-color: #fff;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}
.video-text{
  width: 50%;
  background-color: #fff;
  color: #000;
  font-size: 10pt;
}
video{
  max-height: 100%;
}
</style>

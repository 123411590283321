<template>
<div class="top-bar">
  <div class="social-icons">
    <a v-if="isMobile()" href="whatsapp://send?phone=+79152331677&text="><font-awesome-icon class="icon fa-2x" :icon="['fab', 'whatsapp']"></font-awesome-icon></a>
    <a href="https://vk.com/id699779784" target="_blank">
<!--      <font-awesome-layers class="icon-group">-->
<!--        <font-awesome-icon icon="square" class="fa-2x"/>-->
        <font-awesome-icon :icon="['fab', 'vk']" class="icon fa-2x"/>
<!--      </font-awesome-layers>-->
    </a>
    <a href="https://t.me/sunvillagearkhyz" target="_blank">
        <font-awesome-icon :icon="['fab', 'telegram']" class="icon fa-2x"/>
    </a>
  </div>
</div>
</template>

<script>
export default {
  name: "TopBar",
  methods: {
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    },
  }
}
</script>

<style scoped>
.top-bar {
  height: 40px;
  background-color: #ffc629;
}
@media (max-width: 480px) {
.top-bar{
  padding: 0 .5rem;
}
}

.social-icons {
  text-align: center;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: right;
}

.icon {
  cursor: pointer;
  color: #000;
  margin: 0 10px;
}
.icon-group{
  margin: 0 20px;
}
</style>

<template>
  <section>
    <div :class="{ 'background': background !== null, 'content': true }" :style="cssProps">
      <div class="title-group">
        <div class="title">{{ title }}</div>
      </div>
      <div class="wrap">
        <slot name="content"></slot>
      </div>
    </div>
    <Footer/>
  </section>
</template>

<script>
import Footer from "./footer/Footer";
export default {
  name: "Page",
  components: {Footer},
  props: {
    title: {
      type: String,
      required: true,
    },
    background: {
      type: String,
      default: () => null,
    }
  },
  computed: {
    cssProps(){
      return this.background ? {
        backgroundImage: `url(${this.background})`
      }
      : {};
    }
  },
  mounted() {
    this.$root.$emit('closeMenu');
    window.addEventListener('load', () => {
      this.$root.$emit('loaded');
    });
  }
}
</script>

<style scoped>
.title-group{
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}
.title{
  margin: 2rem 0;
  font-weight: bold;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 26pt;
  line-height: 1.1;
  text-align: left;
  text-transform: uppercase;
  border-bottom: 3px solid #000;
  width: 50%;
  flex: 1;
  z-index: 100;
  height: auto;
  min-height: 45px;
}
.content{
  padding: 110px 2rem 20px;
  background-color: #fff;
  width: 1080px;
  max-width: 100%;
  min-height: 100vh;
  height: auto;
  margin: auto;
  position: relative;
}
.content.background{
  background-size: cover;
  background-repeat: no-repeat;
}
.content.background::before{
  content: '';
  z-index: 0;
  position: absolute;
  top: 0; bottom: 0; left: 0; right: 0;
  background-color: #000;
  opacity: .7;
}
.wrap{
  /*z-index: 10;*/
  /*position: absolute;*/
}
</style>
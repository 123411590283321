<template>
  <div>
    <div class="block-title">
      Клиентам
    </div>
    <div class="corp">
      <ul class="corp-info">
        <li>
          <font-awesome-icon class="icon" :icon="['fa','phone']"></font-awesome-icon>
          <span>+79152331677</span>
        </li>
        <li>
          <font-awesome-icon class="icon" :icon="['fa','envelope']"></font-awesome-icon>
          <span>sunvillage.arkhyz@gmail.com</span>
        </li>
      </ul>
      <div class="ur-info">
        <div>ИП Гришина Елена Владимировна</div>
        <div>ИНН 773471886205 / ОГРН 321508100472467</div>
        <div class="link">
          <router-link to="/legal">Юридическая информация</router-link>
        </div>
        <!--    <div>Юр. Адрес: г.о. Клин, д. Афанасово, 3-я Солнечная д. 21</div>-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
name: "CorpBlock"
}
</script>

<style scoped>
.corp{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
}
.block-title{
  margin: 1rem 0;
  color: #fff;
  font-size: 18pt
}
.corp-info{
  margin: 0;
  padding: 0;
  font-size: 12pt;
  list-style: none;
  line-height: 2;
  color: #bfbfbf;
  text-align: left;
}
.ur-info{
  text-align: right;
  font-size: 12pt;
  color: #b8b8b8;
  line-height: 1.4;
}

.corp-info .icon{
  margin-right: 10px;
  color: #b8b8b8;
}
.ur-info .link{
  margin-top: 5px;
  color: #ffc629;
}
.ur-info .link:hover{
  text-decoration: underline;
}

@media (max-width: 480px) {
  .corp{
    text-align: center;
  }
}
</style>
<template>
  <Page title="Фотогалерея">
    <template v-slot:content>
      <div class="gallery">
        <div class="image" v-for="i in images" :key="i">
          <div class="image-wrap">
            <img :src="`https://svarkhyz.ru/images/gallery/${i}`" @click="openImage(i)">
          </div>
        </div>
      </div>
    </template>
  </Page>
</template>

<script>
import Page from "../layout/Page";
export default {
  name: "Gallery",
  components: { Page },
  methods: {
    openImage(i){
      this.$root.$emit('popup', {type: 'image', content: `/images/gallery/${i}`});
    }
  },
  data() {
    return {
      images: [
        'photo432.jpg',
        'photo433.jpg',
        'photo434.jpg',
        'photo435.jpg',
        'photo436.jpg',
        'photo437.jpg',
        'photo438.jpg',
        'photo439.jpg',
        'photo440.jpg',
        'photo441.jpg',
        'photo442.jpg',
        'photo443.jpg',
        'photo444.jpg',
        'photo445.jpg',
        'photo446.jpg',
        'IMG_0841.jpg',
        'IMG_0842.jpg',
        'photo_2022-08-25_14-15-40.jpg',
        'IMG_0843.jpg',
        'IMG_0844.jpg',
        'IMG_0845.jpg',
        'IMG_0852.jpg',
        'IMG_0857.jpg',
        'IMG_0861.jpg',
        'IMG_0863.jpg',
        'IMG_0866.jpg',
        'IMG_0867.jpg',
        'IMG_0874.jpg',
        'IMG_0878.jpg',
        'IMG_0879.jpg',
        '238324719_269886114587184_7619460205388378650_n_17984616118381773.jpg',
        '64cc0382-3e19-4405-9767-6fb9298f9ec3.JPG',
        'IMG_0654.jpg',
        'IMG_0678.jpg',
        'IMG_3161.jpg',
        'IMG_6601.JPG',
        'IMG_6604.JPG',
        'IMG_6629.JPG',
        'IMG_6648.JPG',
        'IMG_6654.JPG',
        'IMG_6678.JPG',
        'IMG_6682.JPG',
        'IMG_6686.JPG',
        'IMG_6712.JPG',
        'IMG_6720.JPG',
        'IMG_6721.JPG',
        'IMG_6732.JPG',
        'IMG_6734.jpg',
        'IMG_6735.JPG',
        'IMG_6742.JPG',
        'IMG_8116.JPG',
        'IMG_8118.JPG',
        'IMG_8131.JPG',
        'IMG_8133.JPG',
        'IMG_8135.JPG',
        'IMG_8138.JPG',
        'IMG_8143.JPG',
        'IMG_8151.JPG',
        'IMG_8152.JPG',
        'IMG_8162.JPG',
        'IMG_8171.JPG',
        'IMG_8173.JPG',
        'IMG_8180.JPG',
        'IMG_8182.JPG',
        'IMG_8186.JPG',
        'IMG_8195.JPG',
        'IMG_8201.JPG',
        'IMG_8211.JPG',
        'IMG_8222.JPG',
        'IMG_8234.JPG',
        'IMG_8236.JPG',
        'IMG_8276.JPG',
        'IMG_8289.JPG',
        'IMG_8293.JPG',
        'IMG_8301.JPG',
        'IMG_9040.jpg',
        'IMG_9090.jpg',
        'IMG_9356.jpg',
        'IMG_9366.jpg',
        'IMG_9408.jpg',
        'c25f5977-8d2e-458f-823a-89322ef6e67b.JPG',
        'ceed52e1-c274-4d1e-bb9f-b3518c026d8b.JPG',
       'photo_2022-09-13_00-09-09.jpg',
       'photo_2022-09-13_00-09-22.jpg',
       'photo_2022-09-13_00-09-16.jpg',
       'photo_2022-09-13_00-09-15.jpg',
       'photo_2022-09-13_00-09-12.jpg',
       'photo_2022-09-13_00-09-06.jpg',
       'photo_2022-09-13_00-09-04.jpg',
       'photo_2022-09-13_00-09-02.jpg',
       'photo_2022-09-13_00-08-59.jpg',
       'photo_2022-09-13_00-08-56.jpg',
       'photo_2022-09-13_00-08-54.jpg',
       'DJI_0049.jpg',
       'DJI_0048.jpg',
       'DJI_0040.jpg',
       'IMG_2335.jpg',
       'IMG_2338.jpg',
       'IMG_2458.jpg',
       'IMG_2466.JPG',
       'IMG_2470.JPG',
       'IMG_2512.JPG',
       'IMG_2450.jpg',
       'photo_2022-09-13_00-08-43.jpg',
       'photo_2022-09-13_00-08-40.jpg',
       'photo_2022-09-13_00-08-29.jpg',
       'photo_2022-09-13_00-08-27.jpg',
       'photo_2022-09-13_00-08-24.jpg',
       'IMG_1612.jpg',
       'IMG_1624.jpg',
       'IMG_1620.jpg',
       'IMG_1617.jpg',
      ],
    }
  }
}
</script>

<style scoped>
.gallery{
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}
h1{
  width: 100%;
}
.image{
  flex-grow: 1;
  width: 350px;
}
.image-wrap{
  background-color: #fff;
  position: relative;
  height: 300px;
  overflow: hidden;
  cursor: pointer;
  border: 1px solid #484848;
}
.image-wrap:hover img{
  opacity: 0.7;
}
.image-wrap img{
  position: absolute;
  top: 50%; left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
</style>

<template>
  <Page title="Юридеческая информация">
    <template v-slot:content>
      <div class="legal">
        <h2>Юридическая информация</h2>
        <p>ИП Гришина Елена Владимировна</p>
        <p>ИНН 773471886205</p>
        <p>ОГРН 321508100472467</p>
        <h3>Юридический Адрес</h3>
        <p>Юр. Адрес: г.о. Клин, д. Афанасово, 3-я Солнечная д. 21</p>
        <h3>Условия бронирования</h3>
        <p>Для бронирования требуется онлайн-оплата в размере стоимости первой ночи.</p>
        <p>Бесплатная отмена бронирования возможна за 4 дня до даты заезда. В случае отмены менее чем за 4 дня взимается штраф в размере стоимости первой ночи.</p>
        <h3>Возврат средств</h3>
        <p>
          Возврат переведённых средств, производится на ваш банковский счёт в течение 5-30 рабочих дней (срок зависит от банка, который выдал вашу банковскую карту).
        </p>
        <h3>Способ оплаты</h3>
        <h4>Банковской картой</h4>
        <div class="card-options">
          <img src="@/assets/card_options.png"/>
        </div>
        <p>
          Для оплаты и ввода реквизитов Вашей карты Вы будете перенаправлены на платёжный шлюз ПАО СБЕРБАНК.
          Соединение с платёжным шлюзом и передача информации осуществляется в защищённом режиме с использованием протокола шифрования SSL.
          В случае если Ваш банк поддерживает технологию безопасного проведения интернет-платежей
          Verified By Visa, MasterCard SecureCode, MIR Accept, J-Secure,
          для проведения платежа также может потребоваться ввод специального пароля.
        </p>
      </div>
    </template>
  </Page>
</template>

<script>
import Page from "../layout/Page";
export default {
  name: "Legal",
  components: {Page},
}
</script>

<style scoped>
  .legal{
    min-height: 100%;
  }

  @media (max-width: 480px) {
    .legal{
      padding: 1rem;
    }
  }

</style>
<template>
  <div>
    <div v-if="popup" class="popup" @click.self="popup = false">
      <div class="close" @click="popup = false">
        <font-awesome-icon icon="times" class="fa-2x"></font-awesome-icon>
      </div>
      <ImageBox v-if="popupType === 'image'" :image="content"> </ImageBox>
      <div v-else class="text">
       <h1>{{ content.title }}</h1>
        <p v-html="content.text"></p>
      </div>
    </div>
    <Header></Header>
    <div v-if="loading"  class="loader">
      <div class="loader-box">
        <Loader/>
        <img class="loader-image" src="@/assets/logo_128.png" alt="Sun Village Arkhyz"/>
      </div>
    </div>

    <router-view>
    </router-view>
  </div>
</template>

<script>
import Header from "@/components/layout/header/Header";
import ImageBox from "../layout/content/ImageBox";
import Loader from "../extra/Loader";

export default {
  name: "ClientView",
  components: {
    Loader,
    ImageBox,
    Header,
  },
  data(){
    return{
      popup: false,
      popupType: null,
      content: '',
      loading: true,
    }
  },
  created() {
    this.$root.$on('popup', (data) => this.openPopup(data));
    this.$root.$on('loaded', () => {this.loading = false})
  },
  methods:{
    openPopup({type, content}){
      this.content = content;
      this.popupType = type;
      this.popup = true;
    },
  }
}
</script>

<style scoped>
h1{
  line-height: 1;
}
.popup{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.85);
  z-index: 9999;
  padding: 3rem;
}
.popup .text{
  background-color: #fff;
  padding: 3rem 8rem;
  height: 100%;
  overflow-y: scroll;
}
.popup .text p{
  text-align: justify;
}
.loader{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background-color: #b8b8b8;
}
.loader-box{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}
.loader-image{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  width: 8rem;
}

@media (max-width: 480px) {
.popup{
  padding: 3rem 0;
}
.popup .text{
  padding: 1rem;
}
.popup .text h1{
  font-size: 16pt;
}
}
.close{
  position: absolute;
  top: 1rem;
  right: 1rem;
  color: #fff;
  cursor: pointer;
}
</style>

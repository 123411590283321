<template>
  <header ref="header" id="header">
      <Logo v-if="!isMobile()" class="logo"></Logo>
      <Logo  v-else class="logo-mobile"></Logo>
      <TopBar></TopBar>
      <NavBar v-if="!isMobile()"></NavBar>
      <NavBarMobile v-else></NavBarMobile>
  </header>
</template>

<script>
import TopBar from "@/components/layout/header/TopBar";
import NavBar from "@/components/layout/header/NavBar";
import Logo from "@/components/layout/header/Logo";
import NavBarMobile from "@/components/mobile/NavBarMobile";
export default {
  name: "Header",
  components: {NavBarMobile, Logo, NavBar, TopBar},
  methods: {
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    },
  },
}
</script>

<style scoped>
header  {
  width: 100%;
  position: fixed;
  z-index: 999;
}
.logo{
  position: absolute;
  width: 120px;
  top: .5rem;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  border-radius: 50%;
  cursor: pointer;
  z-index: 999;
  border: 1px solid #000;
}
.logo-mobile{
  position: absolute;
  width: 60px;
  top: 10px;
  left: 20px;
  background-color: #fff;
  border-radius: 50%;
}
</style>

<template>
  <Page title="Корпоративным клиентам">
    <template v-slot:content>
      <div class="corporate">
        Парк-отель SUN VILLAGE Arkhyz предлагает выгодные условия сотрудничества для корпоративных клиентов.<br/><br/>
        Мы располагаем номерным фондом для размещения групп до 90 гостей, готовы организовать ваше корпоративное мероприятие в собственном зале вместимостью 120 человек, а так же предоставить услуги кейтеринга.
        Мы и наши корпоративные партнеры готовы предоставить трансфер, банкетное обслуживание, экскурсии и треккинг, площадки для спорта, конференций, обучающих и командообразующих мероприятий.
        <br/><br/>
        Нас выбирают для конференций, деловых переговоров, культурных мероприятий, проведения йога-туров, спортивных сборов и активных командообразований.
        <br/><br/>
        Все услуги включаются в единый пакет предложений, что выгодно и удобно во взаиморасчетах.
        <br/><br/>
        Наши партнеры - профессиональные экскурсоводы, менеджеры, шоумены, ведущие  и специалисты по MICE с многолетним опытом работы на Курорте Архыз, способные организовать и провести мероприятие любой сложности.
        <br/><br/>
        Поддержка корпоративных клиентов и работа над предстоящими мероприятиями ведётся в режиме 24/7.
        <br/><br/><br/>
        <b>Для того чтобы получить предложение по вашему лучшему мероприятию в горах Архыза свяжитесь с нами по телефону: <a href="tel:+79152331677">+7(915)233-16-77</a>, или напишите на почту <a href="mailto:sunvillage.arkhyz@gmail.com">sunvillage.arkhyz@gmail.com</a> </b>
      </div>
    </template>
  </Page>
</template>

<script>
import Page from "../layout/Page";
export default {
  name: "Corporate",
  components: {Page},
}
</script>

<style scoped>
.corporate{
  font-size: 12pt;
}
</style>
<template>
  <Page title="Экскурсии">
    <template v-slot:content>
      <div class="excursions">
        <div v-for="item in excursions" :key="item.id" class="excursions-block" :class="{full: item.full}">
          <img v-if="item.full" :alt="item.title" :src="item.image" class="excursions-block-media-full"/>
          <img class="excursions-block-media" v-else-if="item.image" :alt="item.title" :src="item.image"/>
          <video class="excursions-block-media" v-else-if="item.video" :src="item.video" controls></video>
          <h3 v-if="item.title">{{ item.title }}</h3>
          <div v-if="item.text" class="excursions-block-text" v-html="item.text"></div>
          <div
              v-if="item.popup"
              @click="$root.$emit('popup', {
               type: 'text',
               content: {
                 title: item.title,
                  text: item.fulltext
               }
             })"
              class="excursions-block-button">
            <span>Читать далее</span>
          </div>
        </div>
      </div>
    </template>
  </Page>
</template>

<script>
import exc1 from '@/assets/exc1.jpeg';
import exc2 from '@/assets/exc2.jpeg';
import exchrs from '@/assets/exc_hrs.jpg';
import Page from "../layout/Page";

export default {
  name: "Excursions",
  components: { Page },
  methods: {
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    },
  },
  data: () => ({
    excursions:[
      {
        id: 11,
        image: '/images/exc-photo-1.jpeg',
        full: true,
      },
      {
        id: 12,
        image: '/images/exc-photo-2.jpeg',
        full: true,
      },
      {
        id: 13,
        image: '/images/exc-photo-3.jpeg',
        full: true,
      },
      {
        id: 14,
        image: '/images/exc-photo-4.jpeg',
        full: true,
      },
      {
        id: 4,
        title: 'Пешие и конные экскурсии в горы',
        image: exchrs,
        popup: true,
        text: 'Мягкий климат, близкие расстояния и множество маршрутов по силам не только альпинистам и спортивным туристам, но и всем, кто любит ходить.' +
              ' С собой вы привезёте не только массу впечатлений, но и красивые пейзажи.',
        fulltext: 'Немного лирики от прекрасного поэта, который искренне любит горы Кавказа и конечно Архыз, и замечательного фотографа - Александры Петериной. <br/>' +
            '<br/>' +
            'Зимние фото ставить не стали. Хотим показать, тем кто ещё ни разу не был, как у нас необыкновенно красиво весной и летом 🍃<br/>' +
            '<br/>' +
            'И как вы мне скажите не влюбиться? <br/>' +
            '🏔💛<br/>' +
            '<br/>' +
            'Гурген Долунц <br/>' +
            '       Архыз – жемчужина Кавказа.<br/>' +
            '       Высокогорья край, где скал крутой отвес.<br/>' +
            '       Где сосны - небо закрывают,<br/>' +
            '       Где ледники - тебя встречают<br/>' +
            '       Холодным взглядом от небес. <br/>' +
            '       <br/>' +
            '       Где речки, водопадами срываясь,<br/>' +
            '       Сливаясь в шумный, яростный поток!<br/>' +
            '       В ущелье тесном, пенясь и играя,<br/>' +
            '       На волю, к морю вырваться мечтая,<br/>' +
            '       Кубани вольной, подарив... приток! <br/>' +
            '<br/>' +
            '       Набравшись сил от ледников могучих, <br/>' +
            '       Река - красавец Зеленчук - бурлит!<br/>' +
            '       Несёт в предгорья свои воды,<br/>' +
            '       Исполнит пожелание природы, <br/>' +
            '       Долине горной жажду утолит! <br/>' +
            '<br/>' +
            '       Как крепость, окольцованный горами,<br/>' +
            '       Спасаясь от мирской извечной суеты,<br/>',
      },
      {
        id: 1,
        title: 'Водопад на реке Белой',
        image: exc1,
        text:'Cамый доступный из всех маршрутов, понравится даже начинающим туристам, ведь до него можно доехать на внедорожнике:) ' +
            'Останется пройти всего 300 метров и Вы увидете очень бурную реку с чистейшей водой'
      },
      {
        id: 2,
        title: 'Софийские водопады',
        image:exc2,
        text:'Софийские водопады считаются одними из самых высоких на Кавказе. ' +
            'Дорога к ним не очень сложная, поэтому каждый турист доберётся до них. ' +
            'Водопады берут своё начало на легендарной горе София высотой 3637 метров. ' +
            'Именно здесь находится легендарный, ' +
            'Софийский ледник таяние которого и приводит к образованию водопадов, в сезон их высота достигает 70 метров. ' +
            'Зимой водопады замерзают и выглядят гигантскими сосульками. ' +
            'Весной по льду начинают течь небольшие струйки воды, а большей мощности водопады достигают в середине лета и сохраняют ее до начала осени.'
      },
    ]
  }),
  }
</script>

<style scoped>
  .excursions{
    display: flex;
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: 100%;
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;
    gap: 1rem;
    flex-wrap: wrap;
  }
  @media (max-width: 1400px) {
    .excursions{
      justify-content: space-evenly;
    }
  }
  .excursions-block{
    display: flex;
    flex-direction: column;
    border: 1px solid #b8b8b8;
    padding: 1rem;
    width: 30%;
  }
  .excursions-block.full{
    align-items: center;
    justify-content: center;
  }
  @media (max-width: 1180px) {
    .excursions-block{
      width: 40%;
    }
  }
  @media (max-width: 580px) {
    .excursions-block{
      width: 100%;
    }
  }
  h3{
    margin-bottom: 10px;
  }
  .excursions-block img {
    max-width: 100%;
  }
  .excursions-block-media {
    min-height: 12rem;
    height: 45%;
    background-color: #000000;
  }
  .excursions-block-text {
    flex-grow: 1;
  }
  .excursions-block-button {
    cursor: pointer;
    width: 100%;
    background-color: #ffc629;
    color: #000000;
    text-align: center;
    font-weight: bold;
    padding: .5rem;
    margin-top: 8px;
  }

</style>

<template>
  <div class="box" @click="$emit('click');">
    <img :src="image" :alt="alt"/>
  </div>
</template>

<script>
export default {
  name: "ImageBox",
  props: {
    image: [String, Object],
    alt: String,
  },
  computed: {
  }
}
</script>

<style scoped>
.box{
  height: 100%;
  text-align: center;
  position: relative;
}
.box img{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  max-height: 100%;
}
</style>

<template>
  <div class="">
    <div class="about-content">
      <p class="text">Парк-отель “SUN VILLAGE Arkhyz” находится в сосновом бору с особым микроклиматом,
        рекомендуемый людям страдающими  аллергией,  в окружении гор Западного Кавказа. <br/><br/>
        Комфортабельные дома созданы по принципу «из дома в дом» - оборудованы всей необходимой техникой и кухонной утварью.
        На территории есть детская и спортивная площадки, бесплатный Wi-Fi. <br/><br/>
        Высота 1550 метров комфортна для отдыха в любое время года как в выходные дни так и длительного проживания.<br/><br/>
        Именно отсюда открывается Архыз для истинных путешественников и просто любителей природы и тишины.
        За время отдыха можно  побывать на Софийских озёрах и водопадах, озере Любви, водопаде на реке Белой, Бездонном озере.<br/><br/>
        Мягкий климат, близкие расстояния и множество маршрутов по силам не только альпинистам и спортивным туристам, но и всем, кто любит ходить.<br/><br/>
        И надеемся, что после прогулок СПА зона, в которую входит баня и купель на свежем воздухе, порадует ВАС! <br/><br/>
      </p>
    </div>
    <div class="diplomas">
      <div class="img-wrap">
        <img src="@/assets/dip1.png"/>
      </div>
      <div class="img-wrap">
        <img src="@/assets/dip2.png">
      </div>
      <div class="img-wrap">
        <img src="@/assets/dip4.png">
      </div>
      <div class="img-wrap">
        <img src="https://svarkhyz.ru/images/IMG_NEWS_3.jpg">
      </div>
      <div class="img-wrap">
        <img src="@/assets/dip3.png">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "About",
}
</script>

<style scoped>
  .about-content{
    font-size: 13pt;
    text-align: center;
    line-height: 1.3;
    padding: 1rem 12%;
    background-color: #fff;
  }
  .text{
    white-space: break-spaces;
  }
  .diplomas{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
    background-color: #fff;
    padding: 1rem;
    gap: 8px;
  }
  .diplomas .img-wrap{
    display: flex;
    align-items: center;
    width: 18%;
  }
  .diplomas .img-wrap img{
    border: 5px solid #ffc629;
  }
  @media (max-width: 480px) {
    .diploma .img-wrap{
      width: 100%;
    }
    .about-content {
      font-size: 12pt;
      line-height: 1.3;
      padding: 1rem;
    }
  }
</style>

<template>
    <Page title="Бронирование">
      <template v-slot:content>
        <div class="frame" id="booking_iframe" style="position: relative;">
          <div id="bn_iframe" style='position: absolute;right: 0;margin-top: 12px;font-family: "Proxima nova", "Helvetica Neue", "Cera Pro Medium", Arial, Helvetica, sans-serif;font-size: 12px;line-height: 1em;opacity: 0.5;z-index: 3;bottom:0;'>
            <div style='color: #d0d0d0 !important; background: #d0d0d0'>
              <a style='color: #d0d0d0 !important; background: #d0d0d0 !important;' href="http://bnovo.ru/" id="bnovo_link" target="_blank">Система управления отелем Bnovo ©</a>
            </div></div>
        </div>
      </template>
    </Page>
</template>

<script>
import Page from "../layout/Page";
export default {
  name: "Booking",
  components: {Page},
  computed: {
    ruturnUrl(){
      return 'https://privetmir.ru/russiatravel/';
    }
  },
  mounted() {
    let scriptEl = document.createElement('script')
    scriptEl.setAttribute('src', 'https://widget.reservationsteps.ru/iframe/library/dist/booking_iframe.js')
    scriptEl.onload = () => {
      // eslint-disable-next-line no-undef
      let bi = new BookingIframe({
        html_id: 'booking_iframe',
        uid: '901f17e2-e78a-4708-9f6a-1f3aa654c3f6',
        lang: 'ru',
        width: 'auto',
        height: 'auto',
        rooms: '',
        scroll_to_rooms: '0'
      });
      bi.init();
    }
    document.head.appendChild(scriptEl);
  }
}
</script>

<style scoped>
.frame{
  min-height: 100vh;
}
.frame iframe{
  min-height: 100%;
}
</style>

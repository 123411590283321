<template>
  <Page title="Пресса">
    <template v-slot:content>
      <div class="press">
        <div v-for="item in press" :key="item.id" class="press-block">
          <div class="press-block-media" v-if="item.image">
            <img :alt="item.title" :src="item.image">
          </div>
          <video class="press-block-media" v-else-if="item.video" :src="item.video" controls></video>
          <div class="press-block-media" v-else-if="item.iframe">
            <iframe width="100%" height="100%" :src="item.iframe" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
          <h3>{{ item.title }}</h3>
          <div class="press-block-date">{{ item.date }}</div>
          <div class="press-block-text"><p v-html="item.text"></p></div>
          <div
              v-if="item.popup"
              @click="$root.$emit('popup', {
               type: 'text',
               content: {
                 title: item.title,
                  text: item.fulltext
               }
             })"
              class="press-block-button">
            <span>Читать полностью</span>
          </div>
          <div v-if="item.extraLink" class="press-block-buttons">
            <a :href="item.link" target="_blank">
              <div class="press-block-button">
                rustur.ru
              </div>
            </a>
            <a :href="item.extraLink" target="_blank">
              <div class="press-block-button">
                russiantourism.ru
              </div>
            </a>
          </div>
          <a v-else-if="item.link" :href="item.link" target="_blank">
            <div class="press-block-button">
            <span v-if="item.image">
              К Статье
            </span>
              <span v-else-if="item.video || item.textLink">
              К Статье
            </span>
              <span v-else>
              К Видео
            </span>
            </div>
          </a>
        </div>
      </div>
    </template>
  </Page>
</template>

<script>
import Page from "../layout/Page";
export default {
  name: "Press",
  components: {Page},
  data(){
    return {
      press: [
        {
          id: 9,
          data: '25.12.2023',
          title: 'По поручению Рашида Темрезова представители индустрии гостеприимства прошли курсы повышения квалификации',
          text: 'В Доме Правительства КЧР состоялась церемония вручения удостоверений о повышении квалификации и сертификатов об обучении участникам, успешно прошедшим программу и квалификационные экзамены курса клиентского сервиса.',
          image: 'https://www.kchr.ru/upload/iblock/0d6/photo_2023_12_22_12_56_03.jpg',
          link: 'https://www.kchr.ru/news/detailed/87288/',

        },
        // {
        //   id: 8,
        //   image: '/images/press/press_8.jpg',
        //   title: 'ПАРК-ОТЕЛЬ “SUN VILLAGE Arkhyz”  НОМИНИРОВАН НА ПЕРВУЮ НАЦИОНАЛЬНУЮ ПРЕМИЮ «ГОРЫ РОССИИ»',
        //   text: '«Горы России» - цифровая платформа, объединяющая более 150 курортов страны.\n' +
        //       'Премия «Горы России» проводится в целях развития горнолыжного туризма в России, продвижения культуры активного отдыха в горах и является социально значимым проектом в рамках развития Национального проекта «Туризм и индустрия Гостеприимства» и федеральных целевых программ: Государственная программа Российской Федерации «Развитие туризма» и Государственная программа Российской Федерации «Развитие физической культуры и спорта».\n' +
        //       'С 1 сентября по 15 октября 2022 года проходит открытое народное онлайн-голосование и экспертное голосование по дополнительным номинациям Премии.\n' +
        //       'Проголосовать можно пройдя по ссылке: <a target="_blank" href="https://нашигоры.рф/award-vote">https://нашигоры.рф/award-vote</a>',
        //   date: '10.10.2022',
        //   link: 'https://rustur.ru/park-otel-sun-village-arkhyz-nominirovan-na-pervuyu-nacionalnuyu-premiyu-gory-rossii',
        // },
        {
          id: 7,
          title: '«Другой звук» в парк-отеле SUN VILLAGE Arkhyz',
          text: 'Август в горах Архыза проходит активно. Проводится много спортивных и культурных мероприятий в рамках празднования 100-летия Карачаево-Черкесской Республики и 100-летия села Архыз.',
          date: '17.08.2022',
          link: 'https://rustur.ru/drugoj-zvuk-v-park-otele-sun-village-arkhyz',
          textLink: true,
          iframe: 'https://www.youtube.com/embed/u2w7JZ09-8M',
        },
        {
          id: 6,
          image: '/images/photo_2022-06-29_00-14-33.jpg',
          title: 'В Москве объявили лауреатов национальной премии "ЭКОТЕХ-ЛИДЕР 2022"',
          text: 'Парк-отель "SUN VILLAGE Arkhyz" за развитие экотуризма и создание природоориентируемого объекта в Карачаево-Черкесской Республике',
          date: '29.06.2022',
          link: 'https://tass.ru/novosti-partnerov/15059029',
          popup: true,
          fulltext:
              '27 июня 2022 в образовательном комплексе "АРТ.ТЕХНОГРАД" в Москве прошла торжественная церемония награждения победителей национальной премии в области экологических технологий "ЭКОТЕХ-ЛИДЕР 2022" (12+). Лауреатами стали 28 компаний. Всего же в этом году в Оргкомитет поступило более 100 заявок из 85 регионов России, рассказали в пресс-центре премии.<br/>' +
              'В приветственном слове Председатель Оргкомитета премии "ЭКОТЕХ-ЛИДЕР 2022" Виталий Расницын рассказал, что премия вручается второй год подряд: "В этом году мы решили провести не просто церемонию награждения лауреатов премии, а организовать День "ЭКОТЕХ-ЛИДЕР". Рады, что у многих наших номинантов получилось присутствовать сегодня".<br/>' +
              '"Хочется особо отметить высокий уровень представленных на конкурс проектов. Экспертному совету премии было сложно выбрать лучших. Определяя победителя в той или иной номинации, мы руководствовались критериями новизны, социальной и экономической значимости проекта, а также эффективностью с точки зрения охраны окружающей среды. Поздравляю лауреатов с заслуженной победой", - прокомментировал, вручая награду, председатель экспертного совета премии "ЭКОТЕХ-ЛИДЕР 2022" Сергей Алексеев.<br/>' +
              'Лауреатом национальной премии "ЭКОТЕХ-ЛИДЕР 2022" в номинации «Экотуризм» стал Парк-отель "SUN VILLAGE Arkhyz" за развитие экотуризма и создание природоориентируемого объекта в Карачаево-Черкесской Республике. <br/>' +
              'В своём выступлении Елена Гришина, основатель парк-отеля «SUN VILLAGE Arkhyz», член ТПП Карачаево-Черкесской Республики отметила,что премия «ЭКОТЕХ ЛИДЕР» - площадка неравнодушных людей, которые представляют компании, технологии, проекты. Людей созидателей, для которых экология норма жизни и взгляд в будущее, создающих фундамент экологической безопасности страны.<br/>' +
              '«Мы вносим свой вклад в развитие внутреннего туризма создавая природоориентируемый отель в горах Карачаево-Черкесской республики. Архыз по праву называют жемчужиной Кавказа. Молодой, динамично развивающийся курорт. В Архызе созданы все условия для экологического, культурно-познавательного, спортивного отдыха круглый год и я всех приглашаю к нам, в горы Западного Кавказа»'
        },
        {
          id: 5,
          image: '/images/IMG_NEWS_3.jpg',
          title: 'День Эколога',
          date: '07.06.2022',
          text: 'В День Эколога Фонд им. В.И.Вернадского отметил почётной грамотой члена Торгово-Промышленной палаты Карачаево-Черкесской Республики Парк-отель «SUN VILLAGE Arkhyz»',
          link: 'https://rustur.ru/v-den-ekologa-fond-im-v-i-vernadskogo-otmetil-pochyotnoj-gramotoj-chlena-torgovo-promyshlennoj-palaty-karachaevo-cherkesskoj-respubliki-park-otel-sun-village-arkhyz'
        },
        {
          id: 4,
          video: '/videos/press-video-1.mp4',
          title: '«Горнолыжный» ребёнок',
          date: '23.03.2022',
          text: 'Наши гости часто приезжают на базу с детьми и спрашивают есть ли инструктора для обучения горным лыжам своих детей. Мы рекомендуем две школы которые есть в Архызе. Одна находится недалеко от Софийской поляны (Таулу) на горе «Белая», а вторая в комплексе «Архыз» - Спортивный детский клуб "Архызёнок".',
          fulltext:
              'Наши гости часто приезжают на базу с детьми и спрашивают есть ли инструктора для обучения горным лыжам своих детей. Мы рекомендуем две школы которые есть в Архызе. Одна находится недалеко от Софийской поляны (Таулу) на горе «Белая», а вторая в комплексе «Архыз» - Спортивный детский клуб "Архызёнок".<br/><br/>' +
              '<i><b>Давайте попробуем разобраться, зачем учить ребенка кататься на горных лыжах.</b></i><br/>' +
              'Первое, это иммунитет. Морозный воздух, особенно в горах, отлично его укрепляет. <br/>' +
              '<br/>' +
              'Занятия горными лыжами и сноубордом равномерно "накачивают" мускулатуру всего тела. Конечно, в первую очередь это относится к мышцам ног и прессу. Но и грудные мышцы, и руки тоже полноценно работают.<br/>' +
              '<br/>' +
              'Одно из основных движений в горных лыжах - скручивание. По эффективности этого движения для растущего позвоночника конкуренцию горным лыжам может составить только плавание. <br/>' +
              '<br/>' +
              '"Горнолыжный" ребенок растет гибким, пластичным. У него формируется правильная осанка и красивая фигура.<br/>' +
              '<br/>' +
              'Лыжи, сноуборд – это умение держать баланс. Как результат, лыжники и бордеры имеют исключительно развитый вестибулярный аппарат. <br/>' +
              '<br/>' +
              'Занятия горными лыжами учат ребенка правильно рассчитывать свои силы: с одной стороны, не бояться опасности, с другой - не забывать об осторожности. Этот навык -  вне времени, и наверняка пригодится вашему ребенку не только в спорте.<br/>' +
              '<br/>' +
              'Горные лыжи - экстремальный вид спорта. Конечно, это повод лишний раз поволноваться. Но есть и другая сторона медали: благодаря экстриму ребенок избавляется от бьющей через край энергии и полностью выкладывается на горном склоне - так, что всякие детские глупости становятся ему неинтересны.<br/>' +
              '<br/>' +
              '<br/>' +
              '<i><b>Чем горнолыжник-ребенок отличается от взрослого</b></i><br/>' +
              'Главное отличие ребенка, который встает на горные лыжи, - отсутствие страха.<br/>' +
              'Ребенок за свою недолгую жизнь еще не успел набить шишки, его негативный опыт минимален. Поэтому он ничего не боится. Это и плохо, и хорошо одновременно. Хорошо, потому что ребенок не сгибается в три погибели, боясь пошевелиться на склоне, как это обычно бывает с начинающими взрослыми. Он смело катит вниз с радостной улыбкой на лице. А плохо тем, что отважный ребенок, увы, неосторожен. И никакие прочитанные ему морали не помогут. Занимаясь обучением ребенка, нужно быть очень внимательным и обязательно его страховать.<br/><br/>' +
              'Ребенок легкий. Средний вес пятилетнего ребенка - 18 килограмм. И лыжи или борд у него маленькие. На практике это означает, что ребенок может кататься на лыжах, не имея вообще никаких технических навыков. Поскольку все маленькое и легкое, ребенок может поворачивать, просто вращая свое тело в нужном направлении. Тут тоже есть свои плюсы и минусы. С одной стороны, ребенок почти сразу может получать удовольствие от катания. И значит, вам не придется тащить его на склон, - наоборот, он сам вас потащит. С другой, важно, чтобы ребенок не привык кататься неправильно. Поэтому сразу после того, как он поймет, что затея с лыжами или бордом  ему нравится, нужно учить его правильному катанию.<br/>' +
              'Ребенка нельзя учить кататься так, как учат взрослых. Многое он просто не способен еще понять. Попробуйте, например, объяснить ребенку, что значит кататься в плуге. Означает это, что лыжи нужно поставить под углом друг к другу. Но слово "плуг" пятилетнему ребенку ни о чем не говорит. Объяснять технические азы ему нужно понятными словами: вместо плуга - домик, вместо параллельных лыж - рельсы. <br/><br/>' +
              'Вообще, ребенку лучше даже не объяснять, а показывать - дети любят копировать и без проблем воспроизведут нужное движение. Для ребенка важен самый первый эффект - понравилось или нет. Взрослый обычно понимает свою цель. Если у него что-то не получается, он постарается ответить себе, в чем причина, и попробует снова. Может, он поменяет инструктора или выберет другой курорт. Или вместо лыж попробует прокатиться на борде. С ребенком все не так. Если ему что-то не понравилось, он может упереться и вообще отказаться от идеи научиться кататься на лыжах.  <br/>' +
              'Ребенок слабее взрослого чисто физически. Он быстрее устает, более нетерпим к дискомфорту - снегу, дождю, неудобной обуви. А усталость и капризы - это те самые негативные эмоции, которые могут отбить желание учиться дальше. Их желательно избегать. Так что учить ребенка нужно с поправкой на его еще не слишком богатырские силы.<br/>' +
              '<br/>' +
              '<i><b>С какого возраста можно учить ребенка кататься на лыжах</b></i><br/>' +
              'Вопрос о возрасте - самый популярный. Вообще, каких-то строгих границ не существует. В сети можно найти видео, как полуторагодовалые дети вовсю катаются на борде. Они совсем недавно научились ходить и делают это еще не очень уверенно. Они знают всего несколько слов: мама, папа и дай. А вот на борде катаются уже довольно ловко и даже не падают.<br/>' +
              '<br/>' +
              'Однако инструкторы, которые специализируются на обучении детей, считают, что оптимальный возраст, в котором лучше всего ставить ребенка на лыжи - 3-4 года. Разумеется, можно начинать и позже, если у вас нет цели увидеть своего ребенка профессиональным спортсменом.<br/>' +
              'Но, поставив ребенка на лыжи в три года, не ждите от него немедленных спортивных достижений: полноценный вестибулярный аппарат разовьется у него только к шести годам.<br/>' +
              '<br/>' +
              '<br/>' +
              'Хотелось бы отметить и важные психологические аспекты. Горные лыжи - экстремальный вид спорта. Конечно, это повод лишний раз поволноваться за своего ребенка. Но есть и другая сторона медали: благодаря экстриму ребенок избавляется от бьющей через край энергии и полностью выкладывается на горном склоне - так, что всякие детские глупости становятся ему неинтересны.<br/>' +
              '<br/>' +
              'Занятия горными лыжами учат ребенка правильно рассчитывать свои силы: с одной стороны, не бояться опасности, с другой - не забывать об осторожности.  Этот навык -  вне времени, и наверняка пригодится вашему ребенку не только в спорте. Приобщив ребенка к горным лыжам, вы получите бесценную возможность хорошо провести с ним время, испытать удовольствие и радость от общения друг с другом<br/>' +
              '<br/>' +
              '<br/>' +
              'По материалам ski.ru',
          popup: true,
          link: '',
        },
        {
          id: 3,
          image: '/images/IMG_NEWS_1.jpg',
          title: 'Любви к горам все возрасты покорны',
          date: '07.03.2022',
          text: 'Туристы, проживая на базе чувствуют себя, как дома. Это потому, что комфортабельные 2-х этажные дома созданы по принципу «из дома в дом» и укомплектованы всем необходимым — бытовой техникой, кухонной утварью и удобной мебелью. В них уютно и тепло.',
          link: 'https://nvdaily.ru/info/240622.html'
        },
        {
          id: 2,
          image: '/images/IMG_NEWS_2.jpg',
          title: 'Зимняя сказка в горах Кавказа',
          date: '28.02.2022',
          text: 'Сотрудники базы  очень внимательны  и готовы исполнить любые пожелания гостей. Большое внимание уделяется семьям с детьми. На базе допускают проживание с животными. Есть вкусные завтраки и в стоимость входит баня.',
          link: 'https://rustur.ru/zimnyaya-skazka-v-gorax-kavkaza',
          extraLink: 'http://russiantourism.ru/travels/travels_28839.html'
        },
        {
          id: 1,
          iframe: 'https://www.youtube.com/embed/2K4R5M4ZtbY',
          title: 'SUN VILLAGE Arkhyz глазами туриста',
          date: '23.02.2022',
          text: '',
          link: 'https://www.youtube.com/watch?v=2K4R5M4ZtbY&feature=youtu.be'
        },
      ]
    }
  },
}
</script>

<style scoped>
h1{
  padding: 0 6rem;
}
a:hover{
  text-decoration: underline;
}
.press{
  display: flex;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: 100%;
  flex-direction: row;
  width: 100%;
  justify-content: space-evenly;
  gap: 1rem;
  flex-wrap: wrap;
  background-color: #fff;
}
.press-block{
  display: flex;
  flex-direction: column;
  border: 1px solid #b8b8b8;
  padding: 1rem;
  width: 30%;
}
@media (max-width: 1180px) {
  .press-block{
    width: 40%;
  }
}
@media (max-width: 580px) {
  .press-block{
    width: 100%;
  }
}
h3{
  margin-bottom: 0;
}
.press-block a:hover{
  text-decoration: underline;
}
.press-block > img {
  max-width: 100%;
}
.press-block-media{
  height: 12rem;
  position: relative;
  overflow: hidden;
}
.press-block-media > img{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  margin: 0 auto;
  display: block;
}
.press-block-date{
  color: #bfbfbf;
  margin: .4rem 0;
}
.press-block-text{
  flex-grow: 1;
}

.press-block-button{
  cursor: pointer;
  width: 100%;
  background-color: #ffc629;
  color: #000000;
  text-align: center;
  font-weight: bold;
  padding: .5rem;
  margin-top: 8px;
}

</style>

<template>
  <div id="app">
      <ClientView/>
  </div>
</template>

<script>

import ClientView from "./components/main/ClientView";
export default {
  name: 'App',
  components: {ClientView},
  title: "SUN VILLAGE Arkhyz",
}
</script>

<style>
@import "style/vue-material-custom.css";
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap');

*{
  box-sizing: border-box;
}
html{
  height: 100%;
}
body {
  margin: 0;
  padding: 0;
  height: 100%;
}
#app{
  height: 100%;
}
ul{
  margin: 0;
}
a{
  color: unset;
}
a:hover{
  /*text-decoration: none!important;*/
}

</style>

import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta';
import { MdContent, MdTabs, MdButton } from 'vue-material/dist/components'
import Excursions from "./components/pages/Excursions";
import Press from "./components/pages/Press";
import { library } from '@fortawesome/fontawesome-svg-core';
import { faBars, faSquare, faTimes, faPhone, faCaretLeft, faCaretRight, faCaretUp,
  faCaretDown, faUtensils, faSink, faBed, faHome, faEnvelope, faLocationArrow } from '@fortawesome/free-solid-svg-icons';
import { faFacebookSquare, faVk, faInstagramSquare, faWhatsapp, faTelegram} from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome'
import Content from "./components/layout/Content";
// import Services from "./components/pages/Services";
import Booking from "./components/pages/Booking";
import Gallery from "./components/pages/Gallery";
import Legal from "./components/pages/Legal";
import Events from "./components/pages/Events";
import Corporate from "./components/pages/Corporate";
import Restaurant from "./components/pages/Restaurant";

Vue.use(VueRouter);
Vue.use(VueMeta);
Vue.use(MdContent);
Vue.use(MdTabs);
Vue.use(MdButton);
Vue.config.productionTip = false
library.add( faFacebookSquare, faVk, faWhatsapp, faInstagramSquare, faBars, faSquare, faTimes, faCaretUp,
    faPhone, faCaretLeft, faCaretRight, faCaretDown, faUtensils, faSink, faBed, faHome, faEnvelope, faLocationArrow, faTelegram )

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('font-awesome-layers', FontAwesomeLayers)

const routes = [
  { path: '/', redirect: '/home/main', name: 'root' },
  { path: '/home/:page', component: Content, props: true, name: 'home'},
  { path: '/excursions', component: Excursions},
  { path: '/press', component: Press},
  // { path: '/services', component: Services },
  { name: 'Booking', path: '/reservations', component: Booking},
  { path: '/gallery', component: Gallery},
  { path: '/legal', component: Legal },
  { path: '/events', component: Events },
  { path: '/corporate', component: Corporate },
  { name: 'Restaurant', path: '/restaurant', component: Restaurant },

]

const router = new VueRouter({
  mode: 'history',
  routes
});

new Vue({
  render: h => h(App),
  router
}).$mount('#app')

<template>
  <div class="nav-bar">
    <Menu></Menu>
  </div>
</template>

<script>
import Menu from "@/components/layout/header/Menu";
export default {
  name: "NavBarMobile",
  components: {Menu}
}
</script>

<style scoped>
.nav-bar {
  height: 50px;
  background-color: #484848;
  padding: 0 .5rem;
  color: #b8b8b8;
  border-top: 3px solid #000;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  box-sizing: border-box;
}
</style>
<template>
  <div v-if="!loading" class="wrap">
    <ImageSlider3 class="gallery" :images="images"></ImageSlider3>
    <div class="block-content">
      <h3>Включает в себя:</h3>
      <ul>
        <li>Баня по-Белому</li>
        <li>Купель на свежем воздухе</li>
        <li>Массаж</li>
      </ul>
    </div>
  </div>
</template>

<script>
import ImageSlider3 from "@/components/layout/content/ImageSlider3";
export default {
name: "Sauna",
  components: {ImageSlider3},
  props: {
    page: Object,
    loading: Boolean,
  },
  data: () => ({
    images: [
      '/images/new/RF1_9095.jpg',
      '/images/new/RF1_9087.jpg',
      '/images/new/RF1_9039.jpg',
      '/images/new/RF1_9075.jpg',
      '/images/new/RF1_8937.jpg',
      '/images/new/RF1_8964.jpg',
      '/images/new/RF1_8978.jpg',
      '/images/new/RF1_8988.jpg',
    ]
  }),
}
</script>

<style scoped>
.gallery{
  height: 18rem;
}
.block-content ul{
  padding: .2rem 1rem;
}
.wrap{
  background-color: #fff;
  padding: 1rem;
}
@media (max-width: 480px) {
  .wrap{
    padding: 2rem;
  }
}

</style>

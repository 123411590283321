<template>
  <div class="banner">
    <div class="background">
      <video class="background-video" src="@/assets/bg_video.mp4" autoplay muted loop></video>
      <div class="overlay"></div>
    </div>
    <div class="banner-content center">
      <div class="header">
        <div class="headline">Парк-отель</div>
         S<span class="yellow">U</span>N VI<span class="red">LL</span>AGE <span>Arkhyz</span>
      </div>
      <div class="botline">
      отдых круглый год
      </div>
      <BookingWidget/>
    </div>

  </div>
</template>

<script>
import BookingWidget from "../../extra/BookingWidget";
export default {
  name: "MainBanner",
  components: {BookingWidget},
}
</script>

<style scoped>
.dev{
  position: absolute;
  bottom: 1em;
  right: .5em;
  color: #fff;
  font-size: 13pt;
  font-weight: bold;
}
.banner{
  min-height: 100%;
  width: 100%;
  height: 100vh;
  position: relative;
}
/*.banner:before {*/
/*  content: "";*/
/*  top: 0;*/
/*  left: 0;*/
/*  right: 0;*/
/*  bottom: 0;*/
/*  position: absolute;*/
/*  overflow: hidden;*/
/*  background-image: url('~@/assets/background_winter.jpg');*/
/*  background-size: cover;*/
/*  background-position: center center;*/
/*}*/
.background{
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
}
.background-video{
  background-color: #000;
  min-height: 100%;
  min-width: 100%;
  max-width: 100%;
}
@media (max-width: 1280px) {
  .background-video{
    max-width: unset;
    min-height: 100%;
    min-width: 100%;
    max-height: 100%;
  }
}
.overlay{
  position: absolute;
  top: 0; bottom: 0;
  left: 0; right: 0;
  background-color: rgba(0,0,0,0.3);
}
.center{
  position: absolute;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 400;
}
.header{
  margin-bottom: 40px;
  text-align: center;
  font-size: 45pt;
  color: #bfbfbf;
  text-shadow: 3px 3px 3px #000000;
  line-height: 1;
}
.headline{
  margin-bottom: 20px;
  text-align: center;
  font-size: 26pt;
  color: #fff;
  text-transform: uppercase;
}
.botline{
  margin-bottom: 50px;
  text-align: center;
  font-size: 22pt;
  color: #fff;
  text-transform: uppercase;
}

span.yellow{
  color: #ffc629;
}
span.red{
  color: #f00;
}
</style>

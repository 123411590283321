var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Page',{attrs:{"title":"Пресса"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"press"},_vm._l((_vm.press),function(item){return _c('div',{key:item.id,staticClass:"press-block"},[(item.image)?_c('div',{staticClass:"press-block-media"},[_c('img',{attrs:{"alt":item.title,"src":item.image}})]):(item.video)?_c('video',{staticClass:"press-block-media",attrs:{"src":item.video,"controls":""}}):(item.iframe)?_c('div',{staticClass:"press-block-media"},[_c('iframe',{attrs:{"width":"100%","height":"100%","src":item.iframe,"title":"YouTube video player","frameborder":"0","allow":"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture","allowfullscreen":""}})]):_vm._e(),_c('h3',[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"press-block-date"},[_vm._v(_vm._s(item.date))]),_c('div',{staticClass:"press-block-text"},[_c('p',{domProps:{"innerHTML":_vm._s(item.text)}})]),(item.popup)?_c('div',{staticClass:"press-block-button",on:{"click":function($event){return _vm.$root.$emit('popup', {
             type: 'text',
             content: {
               title: item.title,
                text: item.fulltext
             }
           })}}},[_c('span',[_vm._v("Читать полностью")])]):_vm._e(),(item.extraLink)?_c('div',{staticClass:"press-block-buttons"},[_c('a',{attrs:{"href":item.link,"target":"_blank"}},[_c('div',{staticClass:"press-block-button"},[_vm._v(" rustur.ru ")])]),_c('a',{attrs:{"href":item.extraLink,"target":"_blank"}},[_c('div',{staticClass:"press-block-button"},[_vm._v(" russiantourism.ru ")])])]):(item.link)?_c('a',{attrs:{"href":item.link,"target":"_blank"}},[_c('div',{staticClass:"press-block-button"},[(item.image)?_c('span',[_vm._v(" К Статье ")]):(item.video || item.textLink)?_c('span',[_vm._v(" К Статье ")]):_c('span',[_vm._v(" К Видео ")])])]):_vm._e()])}),0)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }